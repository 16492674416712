import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../InnerBanner/style.scss';

export default function InnerBanner(props) {
  return (
    <section className="inner-banner">
        <Container className='position-relative'>
            <Row>
                <Col md={6}>
                    <h2>{props.InnerBannerTitle}</h2>
                </Col>
                <Col md={6}>
                    <ul>
                        <li>
                            <Link to={props.PreviousPageLink} >{props.PreviousPageName}</Link>
                        </li>
                        <li>{props.CurrentPage}</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
