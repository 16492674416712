import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import LegalSecure from "../../assets/images/legal-and-secure.png";
import CustomerSupport from "../../assets/images/customer-support.png";
import FairplayPolicies from "../../assets/images/fairplay-policies.png";
import Withdraw from "../../assets/images/withdraw.png";
import Offers from "../../assets/images/offers.png";
import "../Footer/style.scss";
import BottomToTop from "../../components/ScrollToTop/BottomToTop";

export default function Footer() {
  return (
    <>
      <footer className="footer-section">
        <Container>
          <Row className="gy-5">
            <Col md={12}>
              <div className="copyright">
                Copyright &copy; 2024. All rights reserved.
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
