import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner/InnerBanner";

export default function TermsOfServices() {
  return (
    <>
      <InnerBanner
        InnerBannerTitle="Terms and Condition"
        PreviousPageName="Home"
        PreviousPageLink="/"
        CurrentPage="Terms and Condition"
      />
      <section className="terma-of-services-page inner-page">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="title">Club Debate - Terms of Use:</h2>
              <p>
                Welcome to Club Debate Algorithm ( Club Debate is a part of Club
                Debate Algorithm Canada. We provide you with access to a free to
                use, interactive platform Club Debate Algorithm (“ Club Debate”)
                which can be downloaded through your app store. You can use Club
                Debate to create, share and view content and connect with other
                users (“Service”). These terms of use along with the Community
                Guidelines (together referred to as “Club Debate / Club Debate
                Algorithm Terms of Use”), and the Privacy Policy (“Privacy
                Policy”) govern your use of the Service
              </p>
              <p>
                This document is an electronic record in terms of Information
                Technology Act, 2000 and rules thereunder pertaining to
                electronic records in various statutes as amended by the
                Information Technology Act, 2000 and rules made thereunder.
              </p>
              <p>
                PLEASE READ THE TERMS CAREFULLY BEFORE USE OF THE SERVICE. THESE
                TERMS CONSTITUTE A LEGAL AND BINDING AGREEMENT BETWEEN YOU (THE
                USER OF THE SERVICE) AND Club Debate Algorithm (THE OWNER OF
                Club Debate ALGORITHM), AND GOVERN YOUR USE OF THE SERVICE. BY
                BROWSING OR CONTINUALLY USING Club Debate, YOU EXPRESSLY ACCEPT
                THESE Club Debate ALGORITHM TERMS OF USE, AND THE PRIVACY
                POLICY, ANY AMENDMENTS THERETO, AND ANY OTHER TERMS OR POLICIES
                WHICH GOVERN THE SERVICE FROM TIME TO TIME. IF YOU DO USE BY NOT
                ACCEPT TO THESE TERMS, YOU ARE NOT ENTITLED TO AVAIL OF/USE THE
                SERVICE, AND ANY YOU OF THE SERVICE SHALL BE UNAUTHORIZED.
              </p>
              <p>
                For the purpose of these Club Debate ( Club Debate Algorithm )
                Terms of Use, the terms "You" "Your" or "User" shall mean any
                person, natural or legal who uses or browses Club Debate. The
                term "We", "Us", "Our" shall mean Club Debate ALGORITHM ( Club
                Debate Algorithm and its affiliates ).
              </p>
              <h2 className="title">DEFINITIONS:</h2>
              <p>
                Certain words and expressions in the Club Debate Terms of Use
                have a specific meaning, and are reproduced below for ease of
                reference:
              </p>
              <p>
                1. 'Content' includes all Templates, text, graphics, images,
                music, software, audio, video, information, or any other
                materials available on Club Debate and/or the Service as well as
                the look and feel of Club Debate.
              </p>
              <p>
                2. 'Devices' means any and all internet enabled devices like
                smart phones, tablets, laptops, desktops, Internet TV, etc.
                which are compatible with and have access to the Internet.
              </p>
              <p>
                3. 'Internet' shall mean the system making use of the TCP/IP
                software protocols known as the internet or the worldwide web
                whatever the communications links may be which connects the user
                (including by way of fixed, mobile, DSL, ISDN, UMTS WiMax or
                other broadband links) including any developments in such
                protocols or any other protocols which may be developed which
                give equivalent, reduced or enhanced functionality compared with
                such protocols.{" "}
              </p>
              <p>
                4. “Community Guidelines” means Club Debate’s community
                guidelines .
              </p>
              <h2 className="title">REGISTRATION:</h2>
              <p>
                This Service can only be availed of by users who have registered
                on Club Debate. You will not be permitted to post any Posts (as
                hereinafter defined) without registering on Club Debate first.
                To register yourself on Club Debate, You may be required to
                provide certain personal information to Club Debate Algorithm (
                Club Debate Algorithm), such as your name, age, gender, email
                address, mobile number. You may only register on Club Debate if
                you are above 18 years of age.
              </p>
              <p>
                On successful registration on Club Debate Algorithm , Club
                Debate ALGORITHM ( Club Debate Algorithm) will intimate You of
                the same, and You will be entitled to avail of the Service.
              </p>
              <h2 className="title">ACCESSING THE SERVICE THROUGH VPN</h2>
              <p>
                You may not access or use the Service by means or any technology
                which conceals Your actual location or provides incorrect
                details of Your location, for example, through use of a virtual
                private network (VPN).
              </p>
              <h2 className="title">USE OF Club Debate</h2>
              <p>
                You may use and the Content therein and avail the Services so
                long as such use is in compliance with applicable law. You may
                not use Club Debate for any purpose not intended under these
                Club Debate / Club Debate Algorithm Terms of Use. You will not
                use Club Debate in any way that harms Club Debate Algorithmor
                directors, employees, affiliates, distributors, partners,
                service providers and/or any other user/ visitor of Club Debate.
              </p>
              <p>
                You may not use the Site in any manner that could damage,
                disable, overburden, block, or impair Club Debate, whether in
                part or in full and whether permanently or temporarily, or
                disallow or interfere with any other party's use and enjoyment
                of Club Debate and the Services.
              </p>
              <h2 className="title">ELIGIBILITY</h2>
              <p>
                Unless otherwise specified, Club Debate is available for
                individuals who have attained the age of majority in their
                jurisdiction. In the case of Canada, this Service is restricted
                to users who are aged 18 years or older unless you use the
                Service with the consent of your parent or legal guardian.If You
                are under the relevant age of majority in Your jurisdiction, You
                may only access or use the Service in accordance with applicable
                law which enables a minor in your jurisdiction to access such a
                service. In Canada, by accessing, browsing, uploading or
                downloading Content and/or Posts and/or downloading and/or
                installing and/or using the Services, YOU represent and warrant
                that YOU are 18 years of age or older and in case YOU are less
                than 18 years of age, YOU may not access the Services, except
                with the consent of, and under the supervision of YOUR parent or
                legal guardian. Therefore, it shall be presumed that any access
                by a minor is with the consent of and under the supervision of
                the parent and/or legal guardian. SOME CONTENT OFFERED OR POSTS
                ON Club Debate MAY NOT BE SUITABLE FOR SOME USERS AND THEREFORE
                VIEWER DISCRETION IS ADVISED. FURTHER, SOME CONTENT OFFERED OR
                POSTS ON Club Debate ALGORITHM MAY NOT BE APPROPRIATE FOR
                VIEWERSHIP BY CHILDREN. PARENTS AND/OR LEGAL GUARDIANS ARE
                RESPONSIBLE FOR ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS
                CONTENT OR POSTS ON Club Debate. As a parent or guardian, you
                acknowledge that but allowing any minor in your care to access
                any Devices through which the Services may be accessible, you
                assume full responsibility for the Content or Postsviewed by
                such minor and Club Debate Algorithm / Club Debate
                Algorithmshall have no responsibility towards such access,
                viewing, AND/OR DOWNLOADING OF CONTENT AND/OR POSTS.
              </p>
              <p>
                As a parent and/or legal guardian, you agree and acknowledge
                that if you allow access to any minor in your care to view such
                Content or Posts, you will always be responsible for the access
                and viewing, as agreed under these Club Debate / Club Debate
                Algorithm Terms of Use. Your access to and use of the Services
                is also subject to all applicable laws, rules, and regulations
                framed thereunder.
              </p>
              <h2 className="title">
                USER GENERATED CONTENT ON Club Debate ( Club Debate ALGORITHM)
              </h2>
              <p>
                The Club Debate platform will be made available on Your app
                store by Club Debate to enable You to share your own (user
                generated) comments, text, pictures and/or videos(“Posts”) with
                other Users on the Site for an interactive experience. You may
                add music, filters, graphics, and other template elements to
                such Posts as may be made available by Club Debate Algorithmon
                Club Debate from time- to- time (“Templates”). Club Debate
                Algorithmhereby grants you a revocable, non-exclusive, royalty
                free and worldwide license to use the Templates solely for the
                purpose of creating and publishing your Posts on Club Debate.
              </p>
              <p>
                Whether or not your Posts are made in combination with Club
                Debate preinstalled Templates, your Posts are considered as
                newly created audio-visual electronic records for which you are
                the publisher and author.
              </p>
              <p>
                By sharing Posts on Club Debate, you understand, acknowledge and
                agree that such Posts may be viewed by the other users on Club
                Debate, and the same can be limited at your own discretion by
                You changing the privacy settings of your user profile to limit
                the audience of your Posts to certain groups only. The Posts
                would also continue to remain on the Club Debate platform until
                removed by You or removed in accordance with the conditions
                mentioned elsewhere in these Club Debate / Club Debate
                AlgorithmTerms of Use.
              </p>
              <p>
                Club Debate Algorithm role is that of an ‘intermediary’ as
                defined under the Information Technology Act, 2000 and the rules
                thereunder, with regard to the Posts. Being an intermediary,
                Club Debate.Tueb / Club Debate Algorithmhas no responsibility
                and / or liability in respect of any Posts on Club Debate ( Club
                Debate Algorithm), including for intellectual property rights
                infringement, defamation, obscenity or any other violation under
                applicable law.
              </p>
              <h2 className="title">POSTS RELATING TO NEWS CONTENT</h2>
              <p>
                In the event You qualify to be a publisher of news and current
                affairs content as defined under the Information Technology
                (Intermediary Guidelines and Digital Media Ethics Code) Rules,
                2021 (“IT Rules”), You are required to furnish details of Your
                user accounts on Club Debate to the Ministry of Information and
                Broadcasting of the Government of Canada (“MIB”), along with
                such other information and documents required by the MIB, in
                accordance with the IT Rules.
              </p>
              <h2 className="title">
                Club Debate( Club Debate ALGORITHM) INTELLECTUAL PROPERTY RIGHTS
              </h2>
              <p>
                No right, title, or interest in any Content is transferred to
                You whether as a result of accessing Club Debate ( Club Debate
                Algorithm ) and availing the Services, or otherwise. Club Debate
                Algorithmreserves all right, title and interest in all such
                Content. Except as authorized under these Club Debate (Club
                Debate Algorithm ) Terms of Use, You may not download, alter,
                copy, modify, distribute, transmit, or derive another work from
                any Content obtained from Club Debate (Club Debate Algorithm).
              </p>
              <p>
                Club Debate Algorithmassert all proprietary rights in and to all
                names and trademarks in connection with Club Debate and the
                Service (except in the Posts). Any use of Club Debate Algorithm
                trademarks or copyright in connection with any service that does
                not belong to Club Debate , unless otherwise authorized in a
                written license agreement, will constitute an infringement of
                Club Debate intellectual property rights and may be actionable
                under applicable law.
              </p>
              <h2 className="text">OWNERSHIP OF POSTS AND ACCOUNT</h2>
              <p>
                You retain all ownership right, title and interest in your
                account and in the Posts (excluding any Templates used) which
                you publish on Club Debate ( Club Debate Algorithm), including
                any images contained therein. However, by submitting the Posts
                on Club Debate (Club Debate Algorithm ), you hereby grant Club
                Debate Algorithm a worldwide, perpetual, royalty free,
                non-exclusive, sub-licensable right to reproduce, distribute,
                issue copies, make available, publicly communicate and display
                copies of the Posts shared by You.
              </p>
              <p>
                The Posts uploaded by You can also be discovered under the
                ‘Search’ tab on Club Debate (Club Debate Algorithm) along with
                other Posts uploaded by other Users. For sake of clarity, (Club
                Debate Algorithm)/ Club Debate Algorithmdoes not or make
                available any audio and/or audio-visual contents that belongs to
                any third-party neither Club Debate Algorithmintends to make
                such third party publishers contents available on Club Debate.
                Any such contents are uploaded and made available by the User at
                their own risk.
              </p>
              <p>
                You represent and warrant that you have taken all requisite
                consents, permissions or licenses required for uploading your
                Post on Club Debate, including, but not limited to, consents
                from individuals featured in your Posts, and/or any consents or
                licenses required for music and/or artwork contained in your
                Post, prior to uploading such Posts on Club Debate (Club Debate
                Algorithm). Club Debate / Club Debate Algorithmdoes not assume
                any responsibility or liability for any Posts including any
                content therein.
              </p>
              <p>
                Club Debate Algorithm / Club Debate Algorithmreserves the right
                to disallow or delete any Posts which it determines, in its sole
                discretion, is in violation of these Club Debate Terms of Use,
                and/or its Community Guidelines. Club Debate / Club Debate
                Algorithmmay also delete or takedown any Posts upon receipt of a
                valid order of a court or authorized Government agency in
                accordance with its obligations as an intermediary under the IT
                Rules.
              </p>
              <h2 className="title">YOUR RESPONSIBILITY</h2>
              <p>
                In addition to the responsibilities set out under the Community
                Guidelines, You Undertake that, without prejudice to Your
                obligation to otherwise comply with applicable laws, rules and
                regulations, You shall not share any information/material or
                Post that: · belongs to another person and to which You do not
                have any right to; · is defamatory, obscene, pornographic,
                paedophilic, invasive of another's privacy (including bodily
                privacy), insulting or harassing on the basis of gender,
                libellous, racially or ethnically objectionable, relating or
                encouraging money laundering or gambling, or otherwise
                inconsistent with or contrary to the laws in force; is harmful
                to child; · infringes any patent, trademark, copyright or other
                proprietary rights; · violates any law for the time being in
                force; · deceives or misleads the addressee about the origin of
                the message or knowingly and intentionally communicates any
                information which is patently false or misleading in nature but
                may reasonably be perceived as a fact; impersonates another
                person (including celebrities or any influencer); · threatens
                the unity, integrity, defence, security or sovereignty of
                Canada, friendly relations with foreign States, or public order,
                or causes incitement to the commission of any cognisable offence
                or prevents investigation of any offence or is insulting to any
                other nation; · contains software viruses or any other computer
                code, files or programs designed to interrupt, destroy or limit
                the functionality of any computer resource; · is patently force
                and untrue, and is written or published in any form, with the
                intent to mislead or harass a person, entity or agency, for
                financial gain or to cause any injury to any person; You further
                undertake that: · You shall not buy, sell, or transfer any part
                of Your account, or profile on Club Debate (Club Debate
                Algorithm ), including your username, login credentials,
                password, badges or Posts. · You shall not report any Posts or
                user accounts unless they are genuinely in violation of any law,
                the Community Guidelines, or any provision of these Club Debate
                (Club Debate Algorithm ) Terms of Use.
              </p>
              <h2 className="text">REPORTING OF POSTS</h2>
              <p>
                In case You believe that any Post on Club Debate violates any
                law, our Community Guidelines, or any provision of these Club
                Debate Terms of Use, you may report such Posts by clicking on
                the ‘report’ button on the Post. You may be required to provide
                reasons for reporting such Posts. Alternatively, You may also
                file a complaint or grievance with Our Grievance Officer, whose
                details are provided in these terms.
              </p>
              <p>
                Upon such Post being reported, Club Debate Algorithm shall take
                up to 24 to 36 hours to acknowledge Your complaint , and upto 15
                (fifteen) days to determine whether such Post is required to be
                taken down from Club Debate. You acknowledge and agree that Club
                Debate/ Club Debate Algorithm’s decision in all such matters
                will be final binding, and Club Debate Algorithm / Club Debate
                Algorithmshall not be liable for any claims in this regard.
              </p>
              <h2 className="text">REPORTING OF ACCOUNTS </h2>
              <p>
                In case You believe that any User account on Club Debate
                violates any law, our Community Guidelines, or any provision of
                these Club Debate Terms of Use, you may report such account by
                clicking on the ‘report’ button on the User’s profile. You may
                be required to provide reasons for reporting such Accounts.
                Alternatively, You may also file a complaint or grievance with
                Our Grievance Officer, whose details are provided below.
              </p>
              <p>
                Upon such account being reported, the details of a user’s
                profile are sent to Club Debate Algorithm / , and Club Debate
                Algorithm / Club Debate Algorithmshall take up to 24 to 36 hours
                to acknowledge your complaint, and upto 15 (fifteen) to
                determine whether such profile, or certain specific posts from
                the profile, are required to be taken down from Club Debate. You
                acknowledge and agree that Club Debate/ ’s decision in all such
                matters will be final binding, and Club Debate / Club Debate
                Algorithmshall not be liable for any claims in this regard.
              </p>
              <h2 className="text"> TERMINATION OF SERVICE</h2>
              <p>
                Club Debate Algorithm/ Club Debate Algorithmreserves the right
                to suspend, limit and/or terminate your use of Your account or
                remove Your Posts on the Club Debate (Club Debate Algorithm)
                platform, or the Service, upon providing you with notice, if, in
                Club Debate / ’s sole discretion and opinion, that you have
                breached its Community Guidelines and/or the Club Debate Terms
                of Use and/or any other policy or guideline which may be issued
                by (Club Debate Algorithm) from time to time. Any suspected
                fraudulent, abusive or illegal activity may be grounds for
                terminating Your user account and may be referred to appropriate
                law enforcement authorities.
              </p>
              <p>
                You may also voluntarily deactivate, suspend or delete Your
                Account by following the instructions at the Club Debate Help
                Centre.
              </p>
              <h2 className="text">GRIEVANCE OFFICER</h2>
              <p>
                You may submit any complaints, concerns, or grievances arising
                out of any matter, including violations of these Terms of Use or
                the Community Guidelines, to the Club Debate Grievance Officer.
                The contact details of the Grievance Officer are as follow:
              </p>
              <p>Mr/ Ms Haissam Karhani</p>
              <p>
                Contact Details: Club Debatealgorithm@gmail.com / 15148851627
              </p>
              <p>
                The Grievance Officer shall acknowledge the complaint within 24
                to 36 hours and dispose of Your complaint within a period of 15
                (fifteen) days from its receipt.
              </p>
              <p>
                You would be required to furnish relevant details pertaining to
                your concern, complaint or grievance, specifically including
                your user details, and details / description of the alleged
                violation, what relief You are requesting, and/or any other
                information which may be required by Club Debate Algorithm to
                assess your concern, complaint or grievance.
              </p>
              <h2 className="title">THIRD PARTY CONTENT</h2>
              <p>
                You may be provided with content, information or links on Club
                Debate(Club Debate Algorithm) which may direct you to third
                party content, websites or applications (“Third Party Content”).
                Club Debate / Club Debate Algorithm’s role is limited to
                providing a communication platform along with hosting services
                to third parties, to enable transmission of Third Party Content
                to You. Club Debate (Club Debate Algorithm )’s role is limited
                to an ‘intermediary’ as defined under the Information Technology
                Act, 2000 in relation to such Third Party Content. Club Debate
                Algorithm/ Club Debate Algorithmaccepts no liability or
                responsibility in respect of Third Party Content. Your use of
                any Third Party content is subject to the terms of use of the
                respective third party website, and Club Debate Algorithm / Club
                Debate Algorithmis not responsible for Your use of any third
                party website. Please exercise your independent judgment while
                viewing any Third Party Content.
              </p>
              <h2 className="text">ADVERTISEMENTS</h2>
              <p>
                Club Debate Algorithm/ Club Debate Algorithmdisclaims any
                liability arising out of the advertisements, usage or viewing of
                products or services advertised on Club Debate.
              </p>
              <p>
                As Club Debate (Club Debate Algorithm) is free, and earns part
                of its revenue through advertisements, You agree not to use any
                ad-blocking software or similar built-in web browser options
                designed to block online advertising while using the Service.
                You agree that Club Debate Algorithm / Club Debate Algorithmhas
                the right to run / play ads during playing your video with or
                without monetization. Playing ads on your video is in our sole
                discretion.
              </p>
              <h4 className="title">FEEDBACK</h4>
              <p>
                Club Debate/ Club Debate Algorithmwelcomes and encourages YOU to
                provide feedback, comments and suggestions for improvements to
                Club Debate ("Feedback"). YOU may submit Feedback at the Help
                Center. YOU acknowledge and agree that all Feedback will be the
                sole and exclusive property of Club Debate / Club Debate
                Algorithmand YOU hereby irrevocably assign to Club Debate/ Club
                Debate Algorithmall of YOUR right, title, and interest in and to
                all Feedback, including without limitation all worldwide patent
                rights, copyright rights, trade secret rights, and other
                proprietary or intellectual property rights therein. At Club
                Debate/ 's request and expense, YOU will execute documents and
                take such further acts as Club Debate/ Club Debate Algorithmmay
                reasonably request to assist Club Debate to acquire, perfect,
                and maintain its intellectual property rights and other legal
                protections for the Feedback. You further acknowledge and agree
                that Club Debate/ Club Debate Algorithmshall not be under an
                obligation to take any action pursuant to the Feedback provided
                by You. Club Debate/ Club Debate Algorithmmay, at its sole
                discretion, decide whether any action is required to be taken
                based on the Feedback received from You. You hereby expressly
                agree to indemnify and keep Club Debate/ Club Debate
                Algorithmharmless against any liabilities that may suffered or
                incurred by Club Debate/ Club Debate Algorithmas a consequence
                of any action taken by Club Debate/ Club Debate
                Algorithmpursuant to Your Feedback.
              </p>
              <h2 className="text">WARRANTY/DISCLAIMER</h2>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW OF CANADA,
                Club Debate IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTY OF
                ANY KIND, EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING
                PROVISION S, YOU ASSUME SOLE RISK AND RESPONSIBILITY FOR
                SELECTING Club Debate TO ACHIEVE YOUR INTENDED RESULTS, AND SOLE
                RESPONSIBILITY FOR THE INSTALLATION OF, USE OF, AND RESULTS
                OBTAINED FROM Club Debate. WITHOUT LIMITING THE FOREGOING
                PROVISIONS, Club Debate ( Club Debate Algorithm) MAKES NO
                WARRANTY THAT Club Debate WILL BE ERROR-FREE, VIRUS FREE, OR
                FREE FROM INTERRUPTIONS OR OTHER FAILURES OR THAT Club Debate
                WILL SATISFY YOUR SPECIFIC REQUIREMENTS OR THAT THE ERRORS WILL
                BE RECTIFIED. YOU UNDERSTAND THAT Club Debate MAY BE BLOCKED OR
                MADE INOPERABLE AND (Club Debate Algorithm ) ASSUMES NO
                RESPONSIBILITY OR LIABILITY FOR THE SAME. Club Debate IS NOT
                FAULT-TOLERANT AND IS NOT DESIGNED OR INTENDED FOR USE IN
                HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE,
                INCLUDING WITHOUT LIMITATION, IN THE OPERATION OF NUCLEAR
                FACILITIES, AIRCRAFT NAVIGATION OR COMMUNICATION SYSTEMS, AIR
                TRAFFIC CONTROL, WEAPONS SYSTEMS, DIRECT LIFE-SUPPORT MACHINES,
                OR ANY OTHER APPLICATION IN WHICH THE FAILURE OF Club Debate
                NETWORK COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE
                PHYSICAL OR PROPERTY DAMAGE (COLLECTIVELY, "HIGH RISK
                ACTIVITIES"). Club Debate ( ) EXPRESSLY DISCLAIMS ANY EXPRESS OR
                IMPLIED WARRANTY OF FITNESS FOR HIGH RISK ACTIVITIES.
              </p>
              <h2 className="text">INDEMNIFICATION</h2>
              <p>
                YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS Club
                Debate.Tueb/ Club Debate Algorithm, ITS SUBSIDIARIES,
                AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, CONSULTANTS AND
                AGENTS (“INDEMNIFIED PARTIES”) FROM AND AGAINST ANY AND ALL
                CLAIMS, LIABILITIES, DAMAGES, LOSSES, COSTS, EXPENSES, FEES
                (INCLUDING REASONABLE ATTORNEYS' FEES AND COSTS) THAT SUCH
                INDEMNIFIED PARTIES MAY INCUR AS A RESULT OF OR ARISING FROM (A)
                ANY INFORMATION (INCLUDING, WITHOUT LIMITATION, YOUR USER
                GENERATED CONTENT (INCLUDING POSTS), FEEDBACK, OR ANY OTHER
                CONTENT) YOU (OR ANYONE USING YOUR ACCOUNT) SUBMIT, POST, OR
                TRANSMIT ON OR ); (B) YOUR (OR ANYONE USING YOUR ACCOUNT'S) USE
                OF Club Debate ; (C) YOUR (OR ANYONE USING YOUR ACCOUNT'S)
                VIOLATION OF THESE Club Debate TERMS OF USE; OR (D) YOUR (OR
                ANYONE USING YOUR ACCOUNT'S) VIOLATION OF ANY RIGHTS OF ANY
                OTHER PERSON OR ENTITY, INCLUDING, WITHOUT LIMITATION, ANY
                COPYRIGHT, PATENT, TRADEMARK, TRADE SECRET OR OTHER PROPRIETARY
                RIGHTS OF ANY PERSON OR ENTITY, OR VIOLATION OF APPLICABLE LAW.
                Club Debate/ Club Debate AlgorithmRESERVES THE RIGHT, AT ITS OWN
                EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY
                MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH
                EVENT YOU WILL COOPERATE WITH Club Debate / Club Debate
                AlgorithmIN ASSERTING ANY AVAILABLE DEFENSES.
              </p>
              <h2 className="text">LIMITATION OF LIABILITY</h2>
              <p>
                UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY, WHETHER IN
                TORT, CONTRACT, OR OTHERWISE, SHALL Club Debate / Club Debate
                AlgorithmBE LIABLE TO YOU OR TO ANY OTHER PERSON OR ENTITY FOR
                ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, REMOTE OR
                CONSEQUENTIAL DAMAGES OR ANY OTHER DAMAGES WHATSOEVER
                (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS,
                LOSS OF GOODWILL, LOSS OF CONFIDENTIAL OR OTHER INFORMATION,
                BUSINESS INTERRUPTION, WORK STOPPAGE, COMPUTER FAILURE OR
                MALFUNCTION, PERSONAL INJURY, LOSS OF PRIVACY, FAILURE TO MEET
                ANY DUTY INCLUDING A DUTY OF GOOD FAITH OR OF REASONABLE CARE,
                NEGLIGENCE (WHETHER ACTIVE OR PASSIVE), AND ANY OTHER PECUNIARY
                OR OTHER LOSS WHATSOEVER) ARISING OUT OF OR IN ANY WAY RELATED
                TO THE USE OR INABILITY TO USE Club Debate , LOSS OF DATA OR
                OTHERWISE UNDER OR IN CONNECTION WITH ANY PROVISION OF THESE
                TERMS, EVEN IN THE EVENT OF FAULT, TORT (INCLUDING NEGLIGENCE,
                AND GROSS NEGLIGENCE), STRICT LIABILITY, BREACH OF CONTRACT, OR
                BREACH OF WARRANTY BY Club Debate/ , AND EVEN IF Club Debate /
                Club Debate AlgorithmHAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. THIS LIMITATION OF LIABILITY SHALL NOT APPLY TO
                LIABILITY FOR DEATH OR PERSONAL INJURY TO THE EXTENT THAT
                APPLICABLE LAW PROHIBITS SUCH LIMITATION. THE FOREGOING
                PROVISIONS SHALL BE ENFORCEABLE TO THE MAXIMUM EXTENT PERMITTED
                BY APPLICABLE LAW.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
