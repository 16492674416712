import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../AboutUs/style.scss";

export default function AboutUs() {
  return (
    <>
      <InnerBanner
        InnerBannerTitle="About Us"
        PreviousPageName="Home"
        PreviousPageLink="/"
        CurrentPage="About Us"
      />
      <section className="about-page inner-page">
        <Container>
          <Row>
            <Col md={12}>
              <p>BIF Algorithm</p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
