import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import LogoDark from "../../assets/images/logo-dark.svg";
import "../Header/style.scss";

export default function Header() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  return (
    <header className={scroll ? "my-navbar fixed" : "my-navbar"}>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container>
            <Navbar.Brand href="">
              <Link className="headerlogo" to="">
                <img src={Logo} alt="TopTeam11" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Link className="headerlogo" to="">
                    <img src={LogoDark} alt="TopTeam11" />
                  </Link>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                  <Link className="nav-link" to="/about-us">
                    About
                  </Link>
                  <Link className="nav-link" to="/terms-and-conditions">
                    Terms & conditions
                  </Link>
                  <Link className="nav-link" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                  <Link className="nav-link" to="/contact-us">
                    Contact us
                  </Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </header>
  );
}
