import React from "react";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import FeatureImage1 from "../../assets/images/1_Set-Reminder.png";
import FeatureImage2 from "../../assets/images/2_Extensive-Contest.png";
import FeatureImage3 from "../../assets/images/3_Create-Team.png";
import FeatureImage4 from "../../assets/images/4_Switch-Team.png";
import FeatureImage5 from "../../assets/images/5_Prediction.png";
import FeatureImage6 from "../../assets/images/6_Reverse.png";
import "./style.scss";

export default function AmazingFeatures() {
  var AmazingFeaturesSlider = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    // centerMode: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Container className="position-relative">
        <Slider {...AmazingFeaturesSlider}>
          <div className="amazing-feature-slide">
            <div className="single-amazing-feature">
              <div className="amazing-feature-img">
                <img className="img-fluid" src={FeatureImage1} alt="" />
              </div>
              <div className="amazing-feature-content">
                <p></p>
              </div>
            </div>
          </div>
          <div className="amazing-feature-slide">
            <div className="single-amazing-feature">
              <div className="amazing-feature-img">
                <img className="img-fluid" src={FeatureImage2} alt="" />
              </div>
              <div className="amazing-feature-content">
                <p></p>
              </div>
            </div>
          </div>

          <div className="amazing-feature-slide">
            <div className="single-amazing-feature">
              <div className="amazing-feature-img">
                <img className="img-fluid" src={FeatureImage1} alt="" />
              </div>
              <div className="amazing-feature-content">
                <p></p>
              </div>
            </div>
          </div>
          <div className="amazing-feature-slide">
            <div className="single-amazing-feature">
              <div className="amazing-feature-img">
                <h4></h4>
                <img className="img-fluid" src={FeatureImage2} alt="" />
              </div>
              <div className="amazing-feature-content">
                <p></p>
              </div>
            </div>
          </div>
        </Slider>
      </Container>
    </>
  );
}
