import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainSlider from "../../components/MainSlider/HeroSection";
import HeroImg from "../../assets/images/hero-img2.png";
import AmazingFeatures from "../../components/AmazingFeatures/AmazingFeatures";
import "../Home/style.scss";
import { Link } from "react-router-dom";

export default function Index() {
  return (
    <>
      <MainSlider />
      <section className="about-section">
        <div className="fantasytitle">Bif Algorithm</div>
        <Container className="position-relative">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="about-img">
                <img className="img-fluid" src={HeroImg} alt="AboutImg" />
                <div className="shape1"></div>
                <div className="shape2"></div>
              </div>
            </Col>
            <Col md={6}>
              <div className="main-title">
                <h2>About Bif Algorithm</h2>
                <p></p>
              </div>
              <Link to="/about-us" className="mw-150 btn btn-primary">
                <span>Learn More</span>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="amazing-feature-section">
        <Container className="position-relative">
          <Col md={12}>
            <div className="main-title text-center">
              <h2>Amazing features</h2>
              <p>
                We are offering some amazing features to our users. take a look
                at few of them here.
              </p>
            </div>
          </Col>
        </Container>
        <AmazingFeatures />
      </section>
    </>
  );
}
