import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../ContactUs/style.scss";
import ContactUsImg from "../../assets/images/contact-us.svg";
import { BiPhoneCall, BiEnvelope, BiMap } from "react-icons/bi";

export default function ContactUs() {
  return (
    <>
      <InnerBanner
        InnerBannerTitle="Contact Us"
        PreviousPageName="Home"
        PreviousPageLink="/"
        CurrentPage="Contact Us"
      />
      <section className="contact-page inner-page">
        <Container>
          <Row>
            <Col md={6}>
              <div className="contact-img">
                <img
                  className="img-fluid"
                  src={ContactUsImg}
                  alt="Contact Us"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="contact-info">
                <h2 className="title">Contact Us On</h2>
                <ul>
                  <li>
                    <a href="callto:15148851627">
                      <BiPhoneCall /> 15148851627
                    </a>
                  </li>
                  <li>
                    <a href="mailto:bifalgorithm@gmail.com">
                      <BiEnvelope /> bifalgorithm@gmail.com
                    </a>
                  </li>
                  <li>
                    <span>
                      <BiMap /> Pierrefonds, QC H8Y 1W3
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
