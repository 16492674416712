import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { FaCloudDownloadAlt } from "react-icons/fa";

export default function HeroCommonSlide({
  title,
  SubTitle,
  discription,
  appleIcon,
  GooglePlayIcon,
  AppleStoreUrl,
  GooglePlayUrl,
}) {
  return (
    <section id="Home" className="w-100">
      <Container>
        <Row className="align-items-center justify-content-between">
          <Col lg={7} xl={7}>
            <div className="hero-content position-relative text-start"></div>
          </Col>
          <Col md={6}>
            <div className="hero-img"></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
