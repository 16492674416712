import React from "react";
// import Slider from "react-slick";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../MainSlider/HeroSection.scss";
import AppleStore from "../../assets/images/app-store.svg";
import GooglePlay from "../../assets/images/google-play.svg";
import HeroCommonSlide from "../HeroCommonSlide/HeroCommonSlide";

export default function HeroSection() {
  var HeroSlider = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider className="hero-section" {...HeroSlider}>
        <HeroCommonSlide
          GooglePlayIcon={GooglePlay}
          appleIcon={AppleStore}
          GooglePlayUrl="https://play.google.com/store/apps/details?id=com.com2us.chronicles.android.google.us.normal"
          AppleStoreUrl="https://www.apple.com/in/itunes"
          SubTitle="Join the ultimate cricketing experience with our fantasy app"
          title="One-Stop Fantasy Cricket Gaming App With Best Experience"
          discription="Download TopTeam11 app and play in real money leagues and win big!"
        />
      </Slider>
    </>
  );
}
