import "./App.css";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
// import { Route, Router, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "./pages/Home/Index";
import AboutUs from "./pages/AboutUs/AboutUs";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/App.scss";
import TermsOfServices from "./pages/TermsOfServices/TermsOfServices";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Faq from "./pages/ContactUs/ContactUs";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="" element={<Index />} exact />
          <Route path="/about-us" element={<AboutUs />} exact />
          <Route
            path="/terms-and-conditions"
            element={<TermsOfServices />}
            exact
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} exact />
          <Route path="/contact-us" element={<Faq />} exact />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
